<template>
    <div
        class="col-span-1 grid h-full content-between"
        :class="{ 'active md:gap-4': isActive }"
        tabindex="0"
        @click="handleClick"
        @keydown.enter="handleClick"
    >
        <div
            class="relative -mt-1 flex gap-4 border-y border-transparent p-4 md:border"
            :class="{ 'active-styles': isActive || isHovered }"
            @mouseenter="isHovered = hasDetails ?? false"
            @mouseleave="isHovered = false"
        >
            <div class="relative min-h-[124px] min-w-[124px] overflow-hidden rounded-none">
                <img
                    v-if="person.image"
                    :src="person.image"
                    class="absolute inset-0 h-full w-full object-cover object-center"
                >
            </div>
            <div class="grid content-center gap-1">
                <h3
                    class="m-0 font-title text-lg font-medium leading-5"
                    :class="isActive || isHovered ? 'text-button-primary-subtle-active' : 'text-neutral-base'"
                >
                    {{ person.name }}
                </h3>
                <p
                    class="m-0 font-body text-sm"
                    :class="isActive || isHovered ? 'text-button-primary-subtle-hover' : 'text-neutral-subtle'"
                >
                    {{ person.position }}
                </p>
                <div
                    v-if="person.tags"
                    class="mt-3 flex items-center gap-x-1.5"
                >
                    <DBadge color="subtle">
                        {{ person.tags[0].title }}
                    </DBadge>

                    <DBadge
                        v-if="show2ndTag"
                        color="subtle"
                    >
                        {{ person.tags[1].title }}
                    </DBadge>
                    <small
                        v-if="(!show2ndTag && person.tags.length > 1) || (show2ndTag && person.tags.length > 2)"
                        class="font-body text-xs font-semibold"
                        :class="isActive || isHovered ? 'text-button-primary-strong' : 'text-neutral-extra-subtle'"
                    >
                        +{{ show2ndTag ? person.tags.length - 2 : person.tags.length - 1 }}
                    </small>
                </div>
            </div>
            <DIcon
                v-if="hasDetails"
                class="ml-auto w-5"
                :class="{ 'rotate-180 transform': isActive }"
                icon="caret-down"
                icon-set="ph"
                type=""
            />
        </div>
        <hr
            class="bg-border-base mx-4 -mt-[1px] h-[1px]"
            :class="isActive ? 'hidden md:block' : ''"
        >
    </div>

    <div
        v-if="hasDetails"
        class="staff-details duration-400 -mt-[1px] grid transition-[grid-template-rows]"
        :class="isActive ? 'z-10 mb-4 grid-rows-[1fr]' : 'grid-rows-[0fr]'"
    >
        <div class="overflow-hidden">
            <div class="border-y px-4 py-6 border-base">
                <!--  Person detail items -->
                <div class="grid gap-x-4 gap-y-8 md:grid-cols-2 lg:grid-cols-3 lg:gap-y-10">
                    <div
                        v-for="(detail, idx) in person.details.items"
                        :key="idx"
                    >
                        <div class="flex items-center gap-2">
                            <p class="m-0 font-body text-sm font-semibold text-neutral-base">
                                {{ detail.title }}
                            </p>
                            <DIcon
                                v-if="detail.icon"
                                class="h-5 w-5"
                                :icon="detail.icon.name"
                                :icon-set="detail.icon.set"
                                :type="detail.icon.type"
                            />
                        </div>

                        <div class="mt-1">
                            <a
                                v-if="detail.link"
                                class="font-body text-sm font-medium text-button-primary-subtle-default hover:text-button-primary-subtle-hover"
                                :href="detail.link.url"
                                :target="detail.link.target"
                                :tabindex="isActive ? 0 : -1"
                            >
                                {{ detail.body }}
                            </a>
                            <p
                                v-else
                                class="font-body text-sm text-neutral-subtle"
                            >
                                {{ detail.body }}
                            </p>
                        </div>
                    </div>

                    <div v-if="person.tags">
                        <p class="m-0 font-body text-sm font-semibold text-neutral-base">
                            Departments
                        </p>
                        <div
                            v-if="person.tags"
                            class="mt-1 flex flex-wrap items-center gap-1.5"
                        >
                            <DBadge
                                v-for="(tag, idx) in person.tags"
                                :key="idx"
                                color="subtle"
                            >
                                {{ tag.title }}
                            </DBadge>
                        </div>
                    </div>
                </div>

                <!--  Biography -->
                <div
                    v-if="person.biography"
                    class="mt-7"
                >
                    <p class="mb-1 font-body text-sm font-semibold text-neutral-base">
                        Biography
                    </p>
                    <p class="m-0 font-body text-sm text-neutral-subtle">
                        {{ person.biography }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { computed, ref } from 'vue'
    import { DBadge, DIcon } from '@digistorm/spark'
    import { isEmpty } from 'lodash-es'

    const emits = defineEmits(['click'])

    const props = defineProps({
        person: Object,
        isActive: Boolean,
    })

    const isHovered = ref(false)

    const hasDetails = computed(() => {
        return !isEmpty(props.person.details.items)
    })

    const show2ndTag = computed(() => {
        return (
            props.person.tags.length > 1 && props.person.tags[1].title.length + props.person.tags[0].title.length < 23
        )
    })

    const handleClick = () => {
        if (!hasDetails.value) return

        emits('click', props.person.id)
    }
</script>

<style lang="scss" scoped>
    .staff-details {
        @screen lg {
            grid-area: desc;
        }
    }

    .active-styles {
        @apply z-10 -mt-1 cursor-pointer rounded-none surface-brand-strong border-brand-subtle text-button-primary-subtle-active;
    }
</style>
