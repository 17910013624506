<template>
    <div
        class="container md:rounded-md"
        :class="`is-${props.width} ${containerBg ? 'surface-background' : ''} ${padding}`"
        v-bind="containerAttributes"
    >
        <slot />
    </div>
</template>

<script setup>
    import { computed } from 'vue'

    const props = defineProps({
        colorPalette: String,
        width: {
            type: String,
            default: 'default',
        },
        containerBg: {
            type: Boolean,
            default: true,
        },
        containerAttributes: {
            type: Object,
            default: () => ({}),
        },
    })

    const palette = computed(() => {
        switch (props.colorPalette) {
            case 'light':
                return 'base-subtle'
            case 'dark':
                return 'color-invert'
            default:
                return 'base'
        }
    })

    const padding = computed(() => {
        if (!props.containerBg) {
            return ''
        }

        switch (palette.value) {
            case 'base':
                return 'px-5 lg:px-0'
            default:
                switch (props.width) {
                    case 'wide':
                        return 'p-5 lg:p-9'
                    case 'extra-wide':
                        return 'p-5 lg:p-11'
                    case 'full':
                        return 'p-5 lg:p-14'
                    default:
                        return 'p-5 lg:p-7'
                }
        }
    })
</script>

<style scoped lang="scss"></style>
