<template>
    <div class="grid max-w-[780px] gap-9 px-4 md:p-0">
        <div>
            <h2
                v-if="props.heading"
                class="mb-4 font-title text-2xl font-semibold text-neutral-base lg:text-3xl"
            >
                {{ props.heading }}
            </h2>
            <p
                v-if="props.text"
                class="mb-0 font-body text-neutral-subtle"
            >
                {{ props.text }}
            </p>
        </div>

        <div class="grid gap-4 md:flex">
            <DInput
                v-model="searchQuery"
                class="w-full md:max-w-[380px]"
                type="search"
                placeholder="Search by staff name or department"
                left-icon="magnifying-glass"
                left-icon-type="small"
            />

            <DSelect
                v-if="departments"
                v-model="selectedDepartments"
                class="w-full md:max-w-[382px]"
                placeholder="All Departments"
                searchable-placeholder="Search Departments"
                :options="departments"
                multiple
                icon="sliders-horizontal"
                icon-set="ph"
            />
        </div>
    </div>

    <div
        v-if="groupedStaff.length"
        class="mt-11 grid"
    >
        <div
            v-for="(row, rowIndex) in groupedStaff"
            :key="rowIndex"
            class="grid gap-x-10"
            :class="'columns-' + props.columns"
        >
            <StaffMember
                v-for="(person, personIndex) in row"
                :key="personIndex"
                :person="person"
                :is-active="activeId === person.id"
                @click="toggleActive"
            />
        </div>
    </div>

    <div
        v-else-if="loading"
        class="mt-11 grid"
    >
        <div
            v-for="index in props.itemsPerPage / props.columns"
            :key="index"
            class="grid gap-x-10"
            :class="'columns-' + props.columns"
        >
            <StaffMemberSkeleton
                v-for="idx in props.columns"
                :key="idx"
            />
        </div>
    </div>

    <div
        v-if="noResults"
        class="mt-11"
    >
        <no-results />
    </div>

    <div
        v-if="totalPages > 1"
        class="mt-11 px-4"
    >
        <DPagination
            :pages="pages"
            @next="nextPage"
            @prev="prevPage"
            @page="goToPage"
        />
    </div>
</template>

<script setup>
    import { computed, onMounted, ref, watch } from 'vue'
    import axios from 'axios'
    import Fuse from 'fuse.js'
    import { map, range, chunk, uniq, flatMap } from 'lodash-es'
    import { DField, DInput, DSelect, DPagination } from '@digistorm/spark'
    import StaffMember from './StaffMember.vue'
    import StaffMemberSkeleton from './StaffMemberSkeleton.vue'
    import NoResults from './NoResults.vue'

    const props = defineProps({
        columns: {
            type: Number,
            default: 2,
            min: 1,
            max: 3,
        },
        itemsPerPage: {
            type: Number,
            default: 12,
        },
        heading: String,
        text: String,
        integration: String,
        department: Array,
    })

    const loading = ref(true)
    const staff = ref(null)
    const departments = ref(null)
    const activeId = ref(null)
    const searchQuery = ref('')
    const fuse = ref(null)
    const selectedDepartments = ref([])
    const filteredStaffCount = ref(null)
    const currentPage = ref(1)

    const getDepartmentOptions = (items) => {
        return Array.from(
            new Set(items.flatMap((item) => item.tags?.map((tag) => tag.title) || []).filter((title) => title)),
        ).map((title) => ({ title, value: title }))
    }

    onMounted(() => {
        axios
            .post('/api/integrations/staff-directory', {
                integration_id: props.integration,
                department: props.department ?? [],
            })
            .then((response) => {
                staff.value = response.data.data.staff
                departments.value = getDepartmentOptions(staff.value)

                fuse.value = new Fuse(staff.value, {
                    keys: ['name', 'position'],
                    includeScore: true,
                    threshold: 0.3, // Adjust threshold based on your preference
                })

                loading.value = false
            })
            .catch((error) => console.log(error))
    })

    const toggleActive = (id) => {
        activeId.value = activeId.value === id ? null : id
    }

    const groupedStaff = computed(() => {
        let filteredStaff = staff.value ?? []

        if (searchQuery.value) {
            filteredStaff = fuse.value.search(searchQuery.value).map((result) => result.item)
        }

        if (selectedDepartments.value && selectedDepartments.value.length) {
            // if not searchQuery just filter by selectedDepartments
            filteredStaff = filteredStaff.filter((person) => {
                return person.tags ? person.tags.some((tag) => selectedDepartments.value.includes(tag.title)) : false
            })
        }

        filteredStaffCount.value = filteredStaff.length

        const startIdx = (currentPage.value - 1) * props.itemsPerPage
        const endIdx = startIdx + props.itemsPerPage

        return chunk(filteredStaff.slice(startIdx, endIdx), props.columns)
    })

    const noResults = computed(() => {
        return staff.value && !groupedStaff.value.length && (searchQuery.value || selectedDepartments.value)
    })

    watch([selectedDepartments, searchQuery], () => {
        currentPage.value = 1
    })

    // Pagination
    const totalPages = computed(() => {
        return Math.ceil(filteredStaffCount.value / props.itemsPerPage)
    })

    const goToPage = (page) => {
        if (page >= 1 && page <= totalPages.value) {
            currentPage.value = page
        }
    }

    const prevPage = (index) => {
        currentPage.value--
    }

    const nextPage = (index) => {
        currentPage.value++
    }

    const pages = computed(() => {
        const pages = range(1, totalPages.value + 1)
        return map(pages, (page) => {
            return {
                page,
                current: currentPage.value === page,
            }
        })
    })
</script>

<style lang="scss" scoped>
    .columns-1 {
        @apply grid-cols-1;
        grid-template-areas:
            'item'
            'desc';
    }

    .columns-2 {
        @screen lg {
            @apply grid-cols-2;
            grid-template-areas:
                'item item'
                'desc desc';
        }
    }
</style>
