// required for HMR to work
import '@/scss/app.scss'

// video js default css
import 'video.js/dist/video-js.css'

import {
    i18n as sparkTranslations,
    DIcon,
    DTable,
    DTableRow,
    DTableCell,
    DTableHeaderCell,
    DTabs,
    DButton,
    DTab,
} from '@digistorm/spark'

import PortalVue from 'portal-vue'

import { createI18n } from 'vue-i18n'

import { createApp } from 'vue/dist/vue.esm-bundler.js'

// ds craft components
import ComponentSection from '@/vue/components/base/ComponentSection.vue'
import ComponentContainer from '@/vue/components/base/ComponentContainer.vue'
import StaffDirectory from '@/vue/components/staff-directory/StaffDirectory.vue'
import Calendar from '@/vue/components/calendar/Calendar.vue'

if (import.meta.hot) {
    import.meta.hot.accept()
}

const app = createApp({
    // Website scripts need to wait for this event OR Flag, so that they can run their scripts after Vue is mounted
    // Otherwise vue will nuke the event listeners added before vue mounted
    mounted() {
        document.dispatchEvent(new CustomEvent('ds-components-mounted'))

        // Set a global flag in case the event is missed
        window.dsComponentsMounted = true
    },
})

// web components
app.component('ComponentSection', ComponentSection)
app.component('ComponentContainer', ComponentContainer)
app.component('StaffDirectory', StaffDirectory)
app.component('Calendar', Calendar)

// global spark components for direct use in twig templates
app.component('DButton', DButton)
app.component('DIcon', DIcon)

app.use(PortalVue)

const i18n = createI18n({
    legacy: false,
    locale: 'en-AU',
    silentTranslationWarn: true,
    messages: sparkTranslations,
})

app.use(i18n)

app.mount('#ds-vue-wrapper')
