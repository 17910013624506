<template>
    <div class="skeleton h-4 w-full rounded bg-gray-200" />
</template>

<style scoped lang="scss">
    .skeleton {
        animation: skeleton-loading 1.2s linear infinite alternate;
    }

    .image {
        @apply h-full;
    }

    .text {
        @apply h-4;
    }

    @keyframes skeleton-loading {
        0% {
            opacity: 40%;
        }
        100% {
            opacity: 100%;
        }
    }
</style>
