<template>
    <div class="container grid max-w-[780px] place-items-center py-6 text-center">
        <div class="mb-6 text-primary-600">
            <svg
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g id="Group">
                    <path
                        id="Vector"
                        d="M24 47C43.9902 46 47.5 31 47 23H24.0098H1C1 23 1.5 45.5 24 47Z"
                        fill="currentColor"
                        fill-opacity="0.1"
                    />
                    <path
                        id="Vector_2"
                        d="M47 24C47 36.7025 36.7025 47 24 47C11.2975 47 1 36.7025 1 24C1 11.2975 11.2975 1 24 1C36.7025 1 47 11.2975 47 24Z"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <g id="Group_2">
                        <path
                            id="Vector_3"
                            d="M29.1538 22.0769C29.1538 25.9854 25.9854 29.1538 22.0769 29.1538C18.1684 29.1538 15 25.9854 15 22.0769C15 18.1684 18.1684 15 22.0769 15C25.9854 15 29.1538 18.1684 29.1538 22.0769Z"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            id="Vector_4"
                            d="M33.3842 33.3842L27.7949 27.7949"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </g>
                </g>
            </svg>
        </div>
        <p class="mb-2 font-medium text-gray-800">
            No results found
        </p>
        <p class="text-sm text-gray-700">
            Try using a different search term or update your filters.
        </p>
    </div>
</template>
