<template>
    <div class="relative">
        <div class="absolute -top-2.5 right-0 -translate-y-full">
            <DBadge :color="badge.color">
                {{ badge.text }}
            </DBadge>
        </div>
        <div class="flex items-center divide-x divide-base">
            <div class="flex-1 py-3 pl-4 pr-5">
                <p class="mb-2 text-4xl font-semibold">
                    {{ data.game_score_us }}
                </p>
                <p class="text-sm font-medium">
                    {{ data.team_description }}
                </p>
            </div>

            <div class="flex-1 py-3 pl-5 pr-4">
                <p class="mb-2 text-4xl font-semibold">
                    {{ data.game_score_opponent }}
                </p>
                <p class="text-sm font-medium">
                    {{ data.athletic_opponent }}
                </p>
            </div>
        </div>

        <div
            class="-mb-5 -ml-4 -mr-4 mt-5 h-[3px]"
            :class="resultBarColor"
        />
    </div>
</template>

<script setup>
    import { computed } from 'vue'
    import { DBadge } from '@digistorm/spark'

    const props = defineProps({
        data: Object,
        event: Object,
        eventContent: Object,
    })

    const badge = computed(() => {
        return props.eventContent?.badge
    })

    const resultBarColor = computed(() => {
        switch (badge.value.color) {
            case 'blue':
                return 'bg-accent-information'
            case 'green':
                return 'bg-accent-success'
            case 'red':
                return 'bg-accent-destructive'
            default:
                return 'bg-transparent'
        }
    })
</script>
