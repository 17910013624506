<template>
    <div>
        <DCalendar
            :events="filteredEvents"
            :locale="props.locale"
            :filters="filters"
            :components="{
                AthleticResults,
            }"
            :options="{
                headerToolbar: false,
                views: {
                    dayGridMonth: {
                        dayMaxEvents: 3,
                    },
                },
                fixedWeekCount: false,
            }"
            @update-filter="onUpdateFilter"
            @date-click="onDateClick"
            @event-click="onEventClick"
            @date-changed="fetchData"
        />

        <!-- used for the bottom sheet modal on mobile -->
        <DModals />
    </div>
</template>

<script setup>
    import { computed, onMounted, ref } from 'vue'
    import axios from 'axios'
    import Fuse from 'fuse.js'
    import { debounce, each, map } from 'lodash-es'
    import { DCalendar, DModals } from '@digistorm/spark'
    import AthleticResults from './AthleticResults.vue'

    const props = defineProps({
        integration: String,
        eventType: Array,
        locale: String,
        apiUrl: String,
    })

    const events = ref([])
    const filters = ref([])
    const activeFilters = ref({})
    const fetchedDates = ref([])

    onMounted(() => {
        axios
            .post(props.apiUrl, {
                integration_id: props.integration,
                event_type: props.eventType,
            })
            .then((response) => {
                events.value = response.data.data.events

                filters.value = map(response.data.meta.filters, (filter) => {
                    return {
                        name: filter.name,
                        field: filter.field,
                        options: map(filter.options, (option) => ({
                            title: option.label,
                            value: option.value,
                        })),
                    }
                })
            })
            .catch((error) => console.log(error))
    })

    const mergeEvents = (newEvents) => {
        const merged = [...events.value]

        each(newEvents, (newEvent) => {
            if (!merged.some((event) => newEvent.id === event.id)) {
                merged.push(newEvent)
            }
        })

        events.value = merged
    }

    // Check if the data for the given date has already been fetched
    const shouldFetchData = (date) => {
        const [year, month] = date.split('-')

        if (fetchedDates.value.includes(`${year}-${month}`)) {
            return false
        }

        fetchedDates.value.push(`${year}-${month}`)
        return true
    }

    const fetchData = debounce((date) => {
        if (!shouldFetchData(date)) {
            return
        }

        axios
            .post(props.apiUrl, {
                integration_id: props.integration,
                event_type: props.eventType,
                start: date,
            })
            .then((response) => {
                mergeEvents(response.data.data.events)
            })
            .catch((error) => console.log(error))
    }, 250)

    const onDateClick = () => {
        // console.log('Date clicked')
    }

    const onEventClick = () => {
        // console.log('Event clicked')
    }

    const filteredEvents = computed(() => {
        // Check if there are no active filters or all filter options are empty
        const hasActiveFilters = Object.keys(activeFilters.value).some((key) => activeFilters.value[key].length > 0)

        if (!hasActiveFilters) return events.value

        return events.value.filter((event) => {
            return Object.entries(activeFilters.value).every(([field, options]) => {
                return (options ?? []).includes(String(event[field]))
            })
        })
    })

    const onUpdateFilter = (filters) => {
        activeFilters.value = filters
    }
</script>
