<template>
    <section
        :data-mode="mode"
        class="ds-component"
        :class="spacing"
        v-bind="sectionAttributes"
    >
        <slot />
    </section>
</template>

<script setup>
    import { computed } from 'vue'

    const props = defineProps({
        colorTheme: String,
        colorPalette: String,
        topSpacing: {
            type: String,
            default: 'md',
        },
        sectionAttributes: {
            type: Object,
            default: () => ({}),
        },
    })

    const palette = computed(() => {
        switch (props.colorPalette) {
            case 'light':
                return 'base-subtle'
            case 'dark':
                return 'color-invert'
            default:
                return 'base'
        }
    })

    const mode = computed(() => {
        if (!props.colorTheme) {
            return palette.value
        }
        return `${props.colorTheme} ${palette.value}`
    })

    const spacing = computed(() => {
        switch (props.topSpacing) {
            case 'lg':
                return 'mt-32'
            case 'md':
                return 'mt-20'
            case 'sm':
                return 'mt-8'
            default:
                return 'mt-0'
        }
    })
</script>

<style scoped lang="scss"></style>
